import { fetchById as fetchProposalById } from 'store/proposals';
import { store } from 'store';
import Notification from './notification.type';
import { fetchById as fetchChatById } from 'store/chats';

export const notificationHandler = (notification: Notification): void => {
  const { object, id, context } = notification;
  if (object === 'proposal') {
    switch (context) {
      case 'messages':
        store.dispatch(fetchChatById({ chatId: id }));
        break;
      case '':
        store.dispatch(fetchProposalById({ proposalId: id }));
        break;
      default:
        break;
    }
  }
};
