import {
  CAN_EDIT_CAMPAIGNS,
  CAN_PREVIEW_CAMPAIGNS,
  CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
  CAN_VIEW_CAMPAIGNS,
  CAN_VIEW_EVERY_BUYER_OBJECTS,
  CAN_VIEW_HIDDEN_FEATURES,
  CAN_EDIT_PRODUCTS,
  CAN_VIEW_PRODUCTS,
  CAN_EDIT_PROPOSALS,
  CAN_VIEW_PROPOSALS,
  CAN_VIEW_CLIENTS,
  CAN_EDIT_CLIENTS,
  CAN_DELETE_PROPOSALS,
  CAN_EDIT_USERS,
  CAN_VIEW_USERS,
  CAN_EDIT_PRODUCT_METADATA,
  CAN_VIEW_PRODUCT_METADATA,
} from 'consts/permissions';

export const NAME = 'user';

export const initialState = {
  id: undefined,
  username: undefined,
  buyer: undefined,
  publisher: undefined,
  token: undefined,
  isLoggedIn: false,
  email: undefined,
  [CAN_VIEW_CAMPAIGNS]: false,
  [CAN_EDIT_CAMPAIGNS]: false,
  [CAN_PREVIEW_CAMPAIGNS]: false,
  [CAN_VIEW_HIDDEN_FEATURES]: false,
  [CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST]: false,
  [CAN_VIEW_EVERY_BUYER_OBJECTS]: false,
  [CAN_VIEW_PRODUCTS]: false,
  [CAN_EDIT_PRODUCTS]: false,
  [CAN_VIEW_PROPOSALS]: false,
  [CAN_EDIT_PROPOSALS]: false,
  [CAN_VIEW_CLIENTS]: false,
  [CAN_EDIT_CLIENTS]: false,
  [CAN_DELETE_PROPOSALS]: false,
  [CAN_EDIT_USERS]: false,
  [CAN_VIEW_USERS]: false,
  [CAN_VIEW_PRODUCT_METADATA]: false,
  [CAN_EDIT_PRODUCT_METADATA]: false,
};
