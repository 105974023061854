import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { NAME } from './consts.consts';

export const fetchAll = createAsyncThunk(
  NAME + '/fetchAll',
  withErrorHandling(async () => {
    const [
      { data: consts },
      { data: productcategories },
      { data: publishers },
      { data: users },
      { data: version },
    ] = await Promise.all([
      adserver({ url: '/consts' }),
      adserver({ url: '/productcategories' }),
      adserver({ url: '/publishers' }),
      adserver({ url: '/consts/users' }),
      adserver({ url: '/version' }),
    ]);

    const categories = productcategories
      .filter(category => category.isLeaf === true)
      .map(category => {
        if (category.parentId) {
          const parent = productcategories.find(
            parent => parent.id === category.parentId,
          );
          return {
            id: category.id,
            name: `${parent.name} > ${category.name}`,
          };
        }
        return {
          id: category.id,
          name: category.name,
        };
      });

    return {
      ...consts,
      version,
      productcategories,
      publishers,
      categories,
      users,
    };
  }),
);
