import { RootState } from 'store';
import adapter from './chats.adapter';
import { REDUCER_KEY } from './chats.consts';
import { ChatsParams } from './chats.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): ChatsParams | null =>
  selectors.selectById(state, id) || null;

export function selectChat(
  state: RootState,
  chatId: number,
): ChatsParams | undefined {
  return selectors.selectById(state, chatId) || undefined;
}

export function selectChatMessagesById(
  state: RootState,
  chatId: number,
): ChatsParams['messages'] {
  const chat = selectors.selectById(state, chatId);
  return chat ? chat.messages : [];
}
