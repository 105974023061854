import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Notification from './notification.type';

export interface NotificationState {
  notifications: Notification[];
  isEstablishingConnection: boolean;
  isConnected: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  isEstablishingConnection: false,
  isConnected: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startConnecting: state => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: state => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    },
    objectUpdated: (
      state,
      action: PayloadAction<{
        notification: Notification;
      }>,
    ) => {
      state.notifications.push(action.payload.notification);
    },
    broadcast: (state, action: PayloadAction<{ content: string }>) => {
      return;
    },
  },
});

export const {
  startConnecting,
  connectionEstablished,
  objectUpdated,
  broadcast,
} = notificationSlice.actions;
export const { reducer } = notificationSlice;
