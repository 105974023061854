import {
  CAN_EDIT_CLIENTS,
  CAN_EDIT_CAMPAIGNS,
  CAN_PREVIEW_CAMPAIGNS,
  CAN_VIEW_CLIENTS,
  CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
  CAN_VIEW_CAMPAIGNS,
  CAN_VIEW_EVERY_BUYER_OBJECTS,
  CAN_EDIT_EVERY_BUYER_OBJECTS,
  CAN_VIEW_HIDDEN_FEATURES,
  CAN_EDIT_PRODUCTS,
  CAN_VIEW_PRODUCTS,
  CAN_EDIT_PROPOSALS,
  CAN_VIEW_PROPOSALS,
  CAN_DELETE_PROPOSALS,
  CAN_EDIT_USERS,
  CAN_VIEW_USERS,
  CAN_VIEW_PRODUCT_METADATA,
  CAN_EDIT_PRODUCT_METADATA,
} from 'consts/permissions';

export function normalizeUserData(userObject) {
  const {
    id,
    buyer,
    publisher,
    permissions,
    email,
    firstName,
    lastName,
  } = userObject;
  return {
    id,
    firstName,
    lastName,
    buyer,
    publisher,
    email,
    isLoggedIn: true,
    [CAN_VIEW_CAMPAIGNS]: permissions.includes(CAN_VIEW_CAMPAIGNS),
    [CAN_EDIT_CAMPAIGNS]: permissions.includes(CAN_EDIT_CAMPAIGNS),
    [CAN_PREVIEW_CAMPAIGNS]: permissions.includes(CAN_PREVIEW_CAMPAIGNS),
    [CAN_VIEW_CLIENTS]: permissions.includes(CAN_VIEW_CLIENTS),
    [CAN_EDIT_CLIENTS]: permissions.includes(CAN_EDIT_CLIENTS),
    [CAN_VIEW_HIDDEN_FEATURES]: permissions.includes(CAN_VIEW_HIDDEN_FEATURES),
    [CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST]: permissions.includes(
      CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
    ),
    [CAN_VIEW_EVERY_BUYER_OBJECTS]: permissions.includes(
      CAN_VIEW_EVERY_BUYER_OBJECTS,
    ),
    [CAN_EDIT_EVERY_BUYER_OBJECTS]: permissions.includes(
      CAN_EDIT_EVERY_BUYER_OBJECTS,
    ),
    [CAN_VIEW_PRODUCTS]: permissions.includes(CAN_VIEW_PRODUCTS),
    [CAN_EDIT_PRODUCTS]: permissions.includes(CAN_EDIT_PRODUCTS),
    [CAN_VIEW_PROPOSALS]: permissions.includes(CAN_VIEW_PROPOSALS),
    [CAN_EDIT_PROPOSALS]: permissions.includes(CAN_EDIT_PROPOSALS),
    [CAN_DELETE_PROPOSALS]: permissions.includes(CAN_DELETE_PROPOSALS),
    [CAN_EDIT_USERS]: permissions.includes(CAN_EDIT_USERS),
    [CAN_VIEW_USERS]: permissions.includes(CAN_VIEW_USERS),
    [CAN_VIEW_PRODUCT_METADATA]: permissions.includes(
      CAN_VIEW_PRODUCT_METADATA,
    ),
    [CAN_EDIT_PRODUCT_METADATA]: permissions.includes(
      CAN_EDIT_PRODUCT_METADATA,
    ),
  };
}
