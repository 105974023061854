import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore, Store } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { persistStore } from 'redux-persist';
import notoficationMiddleware from 'store/notifications/notification.middleware';

import adserver from 'services/adserver';
import rootReducer from './rootReducer';

const logger = createLogger({
  collapsed: true,
});

export const store: Store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk.withExtraArgument(adserver),
    promise,
    logger,
    notoficationMiddleware,
  ],
});

export default store;
export const persistor = persistStore(store);
export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
