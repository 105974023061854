import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './proposals.consts';
import { ProposalParams, ProposalListParams } from './proposals.types';
import { selectCurrentCampaignId } from 'store/campaigns/campaigns.selectors';
import {
  normalizeDateFromApi,
  normalizeDateForApi,
} from 'helpers/datetimeHelpers';

function normalizeApiReponse(data: ProposalParams) {
  const campaignStartDate = normalizeDateFromApi(data.campaign.startDate);
  const campaignEndDate = normalizeDateFromApi(data.campaign.endDate);
  const timings = data.proposalValue?.timings.map(timing => ({
    ...timing,
    startDate: normalizeDateFromApi(timing.startDate),
    endDate: normalizeDateFromApi(timing.endDate),
  }));

  return {
    ...data,
    campaign: {
      ...data.campaign,
      startDate: campaignStartDate,
      endDate: campaignEndDate,
    },
    proposalValue: {
      ...data.proposalValue,
      timings,
    },
  };
}

function normalizeApiRequest(data: ProposalParams) {
  const timings = data.proposalValue?.timings.map(timing => ({
    ...timing,
    startDate: normalizeDateForApi(timing.startDate),
    endDate: normalizeDateForApi(timing.endDate),
  }));

  return {
    ...data,
    proposalValue: {
      ...data.proposalValue,
      timings,
    },
  };
}

const fetchByIdHandler = async ({ proposalId }: { proposalId: number }) => {
  const deal = await adserver({
    url: `/proposal/${proposalId}`,
    headers: {},
  });

  const surcharges = await adserver({
    url: `/proposal/${proposalId}/surcharges`,
    headers: {},
  });

  return { ...normalizeApiReponse(deal.data), surcharges: surcharges?.data };
};

export const fetchAllProposals = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/proposals', headers: {} });
    return response.data.map((proposal: ProposalListParams) => proposal);
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ proposalId }: { proposalId: number }) => {
    const response = await adserver({
      url: `/proposal/${proposalId}`,
      headers: {},
    });
    const surcharges = await adserver({
      url: `/proposal/${proposalId}/surcharges`,
      headers: {},
    });
    return {
      ...normalizeApiReponse(response.data),
      surcharges: surcharges?.data,
    };
  }),
);

export const setCurrentProposal = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (proposalId: number) => {
    if (!proposalId) return;
    const proposal = await fetchByIdHandler({ proposalId });
    return proposal;
  }),
);

export const fetchProposalsOfCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchProposalsOfCampaign',
  withErrorHandling(async ({ campaignId }: { campaignId: number }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/proposals`,
      headers: {},
    });
    return response.data.map((deal: ProposalParams) =>
      normalizeApiReponse(deal),
    );
  }),
);

export const fetchAllProposalsForCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchAllProposalsForCurrentCampaign',
  withErrorHandling(async (_: any, thunkApi: any) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    const response = await adserver({
      url: `/campaign/${campaignId}/proposals`,
      headers: {},
    });

    return response.data.map((deal: ProposalParams) =>
      normalizeApiReponse(deal),
    );
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (product: number, thunkApi: any) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    if (!campaignId) throw new Error('no campaignId');
    const response = await adserver({
      url: `/campaign/${campaignId}/proposal/`,
      method: 'POST',
      headers: {},
      data: product,
    });
    return normalizeApiReponse(response.data);
  }),
);

export const submit = createAsyncThunk(
  REDUCER_KEY + '/submit',
  withErrorHandling(async (data: ProposalParams) => {
    const { id: proposalId } = data;
    const requsetdata = normalizeApiRequest(data);
    const response = await adserver({
      url: `/proposal/${proposalId}/submit`,
      method: 'POST',
      headers: {},
      data: requsetdata,
    });
    return normalizeApiReponse(response.data);
  }),
);

export const accept = createAsyncThunk(
  REDUCER_KEY + '/accept',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/accept`,
      method: 'POST',
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const rectify = createAsyncThunk(
  REDUCER_KEY + '/rectify',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/rectify`,
      method: 'POST',
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const generateOrder = createAsyncThunk(
  REDUCER_KEY + '/generate_order',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/generate_order`,
      method: 'POST',
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const renegotiate = createAsyncThunk(
  REDUCER_KEY + '/rectify',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/renegotiate`,
      method: 'POST',
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const cancel = createAsyncThunk(
  REDUCER_KEY + '/cancel',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/cancel`,
      method: 'POST',
      headers: {},
    });
    return normalizeApiReponse(response.data);
  }),
);

export const removeAllProposals = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});

export const fetchProposalFormRate = createAsyncThunk(
  REDUCER_KEY + '/fetchFormRate',
  withErrorHandling(
    async ({
      proposalId,
      formData,
    }: {
      proposalId: number;
      formData: ProposalParams;
    }) => {
      const response = await adserver({
        url: `/proposal/${proposalId}/rate`,
        method: 'POST',
        data: normalizeApiRequest(formData),
        timeout: 10000,
        headers: {},
      });
      return response;
    },
  ),
);
