import { notificationHandler } from './notification.helper';
import { Middleware } from 'redux';
import { io, Socket } from 'socket.io-client';
import {
  startConnecting,
  connectionEstablished,
  objectUpdated,
  broadcast,
} from './notification.slice';
import Notification from './notification.type';

const socketMiddleware: Middleware = store => {
  let socket: Socket;

  return next => action => {
    const isConnectionEstablished =
      socket && store.getState().notification.isConnected;

    if (startConnecting.match(action)) {
      socket = io(process.env.REACT_APP_ADSERVER_SOCKET as string, {
        auth: {
          token: store.getState().user.token,
        },
        reconnectionAttempts: 3,
        forceNew: true,
        path: '/socket.io',
      });

      socket.on('connect', () => {
        console.log('Connected to adserver');
        console.log(socket);
        store.dispatch(connectionEstablished());
      });

      socket.on('objectUpdated', (notification: Notification) => {
        notificationHandler(notification);
        store.dispatch(objectUpdated({ notification }));
      });
    }

    if (broadcast.match(action) && isConnectionEstablished) {
      socket.emit('broadcast', action.payload.content);
    }

    next(action);
  };
};

export default socketMiddleware;
